.input-module {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px; /* Sets the maximum width of the form */
    margin: 0 auto; /* Centers the form in the parent container */
  }
  
  .form-group label {
    display: block;
    color: #333;
    margin-bottom: 5px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 15px;
  }
  
  .objective-input {
    margin-bottom: 10px;
  }
  
  .add-objective-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 20px; /* Adds space below the +Add Objective button */

  }
  
  .submit-btn {
    background-color: #28a745;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
  }

  