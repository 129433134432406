

.discount-pair {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
  .discount-pair-item {
    flex: 1;
  }

  .fee-group {
    padding: 20px; /* Add some padding inside the paper */
    margin-bottom: 20px; /* Add some margin at the bottom of each paper for spacing */
    border-radius: 4px; /* Optional: add border radius for rounded corners */
    background-color: #e30b0b;
}

