/* Add this to your CSS file */
@keyframes slideFadeIn {
    from {
      clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  
  .slideFadeInAnimation {
    animation: slideFadeIn 2s ease forwards;
  }

  
